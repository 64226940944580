/* eslint-disable react/jsx-one-expression-per-line */

import "@stylesComponents/Tab.scss";
import { useState } from "react";
import ArrowIcon from "@icons/arrow-up.png";

export default function Tab({ title, text }) {
  const [tab, setTab] = useState(false);
  return (
    <div className="tab">
      <button
        type="button"
        onClick={() => {
          setTab(!tab);
        }}
        className="tab__title"
      >
        {title}
        <img
          src={ArrowIcon}
          alt="Arrow"
          className={`tab__icon${tab ? "" : " tab__icon--active"}`}
        />
      </button>
      {tab && <p className="tab__text">{text}</p>}
    </div>
  );
}
