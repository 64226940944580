import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { useCompany } from "@hooks";

import "@stylesPages/Terms.scss";

import SEO from "@components/SEO";
import Layout from "@components/Layout";
import Tab from "@components/Tab";

export default function Terms() {
  const { t } = useTranslation("terms");
  const company = useCompany("MX");

  return (
    <Layout>
      <SEO title="Terms" />
      <section className="terms">
        <div className="terms__container">
          <h1 className="terms__title">{t("title")}</h1>
          <div className="terms__data">
            <Tab title={t("faqTitleOne")} text={t("faqTextOne")} />
            {/* <Tab title={t("faqTitleTwo")} text={`${t("faqTextTwo")} ${companyText}`} /> */}
            <Tab title={t("faqTitleTwo")} text={`${t("faqTextTwo")} ${company}`} />
            <Tab title={t("faqTitleThree")} text={t("faqTextThree")} />
            <Tab title={t("faqTitleFour")} text={t("faqTextFour")} />
            <Tab title={t("faqTitleFive")} text={t("faqTextFive")} />
            <Tab title={t("faqTitleSix")} text={t("faqTextSix")} />
            <Tab title={t("faqTitleSeven")} text={t("faqTextSeven")} />
            <Tab title={t("faqTitleEight")} text={t("faqTextEight")} />
            <Tab title={t("faqTitleNine")} text={t("faqTextNine")} />
            <Tab title={t("faqTitleTen")} text={t("faqTextTen")} />
            <Tab title={t("faqTitleEleven")} text={t("faqTextEleven")} />
            <Tab title={t("faqTitleTwelve")} text={t("faqTextTwelve")} />
            <Tab title={t("faqTitleThirteen")} text={t("faqTextThirteen")} />
            <Tab title={t("faqTitleFourteen")} text={t("faqTextFourteen")} />
          </div>
        </div>
      </section>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
